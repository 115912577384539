import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newTitleForm", "translationRu", "translationEn" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    console.log("New project title form controller")
  }

  titleChanged(e) {
    this.newTitleFormTarget.classList.toggle("d-none", e.target.value != "")
  }

  translationChange(e) {
    e.preventDefault()
    this.translationRuTarget.value = e.target.getAttribute("data-translation-ru")
    this.translationEnTarget.value = e.target.getAttribute("data-translation-en")
  }
}
