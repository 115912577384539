import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var allowClear = $(this.element).find('option:first-child').val() == ''
  	$(this.element).select2({
      theme: "bootstrap",
      allowClear: allowClear,
      placeholder: 'Выберите проект…',
      templateResult: function(state) {
        // console.log(state);
        var html = '';
        html += '<span>';
        html += state.text
        if(state.brief) {
          // console.log('here');
          html += '<br>';
          html += '<small style="opacity: 0.8;">';
          html += state.brief;
          html += '</small>' 
        }
        html += '</span>';
        return $(html);
      },
      ajax: {
        url: '/projects/search',
        processResults: function (results) {
          console.log(results);
          return {
            results: $.map(results, function(result) {
              var brief = result.start && result.end ? result.start + ' – ' + result.end : ''
              return {
                id: result.id,
                text: result.name_with_parent_name,
                brief: brief
              };
            })
          };
        }
      }
    });
    // $(this.element).on('select2:unselecting', function(e) {
    //   console.log('select2 event', e)
    // });
  }
}
