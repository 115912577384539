import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "button" ]
  static values = {
    statuses: Array
  }

  connect() {
    console.log("accounting/document_status_form_switch_controller", this.statusesValue)
  }

  click() {
    const currentStatus = this.inputTarget.value
    const currentStatusIndex = this.statusesValue.indexOf(currentStatus)
    const nextStatusIndex = (currentStatusIndex + 1) % this.statusesValue.length
    const nextStatus = this.statusesValue[nextStatusIndex]

    console.log(currentStatus + " => ", nextStatus)
    this.inputTarget.value = nextStatus
    this.buttonTargets.forEach((el) => {
      el.classList.toggle("hidden", el.value != nextStatus)
    })
  }
}
