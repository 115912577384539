import { Controller } from "stimulus"

export default class extends Controller {
  // static values = { container: String }

  connect() {
    console.log("Bootstrap datepicker")
    let options = {
      format: "dd.mm.yyyy",
      weekStart: 1,
      clearBtn: true,
      autoclose: true,
      todayHighlight: true
    }

    const runningInModal = $(this.element).parents(".modal").length > 0
    if (runningInModal) {
      options.container = $(this.element).parents(".modal")
    }

    $(this.element).bootstrapDP(options)
  }
}