import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).select2({
      theme: "bootstrap"
    })
    $(this.element).one("change", this.select2_changed.bind(this))
  }

  select2_changed(select2_event) {
    const js_event = new Event('change')
    this.element.dispatchEvent(js_event)
    $(this.element).one("change", this.select2_changed.bind(this))
  }
}
