$(document)
  .off('ajax:before.remote-checkbox')
  .on('ajax:before.remote-checkbox', 'input[type=checkbox][data-remote=true]', function() {
    var $this  = $(this);

    // Ensure value is a 1 or 0
    var value = this.checked * this.checked;

    // Append value to current params
    var currentParams = $this.data('params');
    var params = (
      (currentParams ? currentParams + '&' : '') +
      $this.attr('name') + '=' + value
    );

    $this.data('params', params).attr('disabled', true);
  })
  .on('ajax:complete', function(event) {
    $(event.target).removeAttr('disabled');
  }
);