import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }
  static targets = [ "transmissionSelect", 'restrictionsTextRu', 'restrictionsTextEn' ]

  call(e) {
    e.preventDefault()

    $.rails.ajax({
      url: this.urlValue,
      data: {
        transmission_ids: this.transmissionIds,
      },
      dataType: 'json',
      success: function(response) {
        this.setRestrictions(response)
      }.bind(this),
    })
  }

  get transmissionIds() {
    return this.transmissionSelectTargets.filter(el => {
      const nestedFieldsElement = el.parentElement.parentElement
      return window.getComputedStyle(nestedFieldsElement).display !== "none"
    }).map(el => {
      return parseInt(el.value);
    });
  }

  setRestrictions(json) {
    if (json.ru) {
      this.restrictionsTextRuTarget.value = json.ru
    }
    if (json.en) {
      this.restrictionsTextEnTarget.value = json.en
    }
  }
}