import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  setDescription(event) {
    const text = event.currentTarget.title
    this.inputTarget.value = text
  }
}
