import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'release',
    'cinema',
    'hall',
    'datetime',
    'dateTimes'
  ]

  connect() {
    var self = this

    $(this.releaseTarget).selectpicker({
      liveSearch: true
    })

    $(this.cinemaTarget).selectpicker({
      liveSearch: true
    })

    $(this.hallTarget).selectpicker()

    this.updateHallsAvailability()
  }

  currentReleaseDates() {
    if(!this.releaseTarget.value) {
      return null
    }
    var option = this.releaseTarget.options[this.releaseTarget.selectedIndex]
    var startDate = option.getAttribute('data-start-date')
    var endDate = option.getAttribute('data-end-date')
    return {
      startDate: flatpickr.parseDate(startDate, "Y-m-d H:i"),
      endDate: flatpickr.parseDate(endDate, "Y-m-d H:i")
    }
  }

  currentReleasesRequiresSattellite() {
    return this.releaseTarget.value && this.releaseTarget.options[this.releaseTarget.selectedIndex].getAttribute('data-satellite') == 'true'
  }

  releaseSelected() {
    console.log('release selected')

    this.datetimeTargets.forEach((el, i) => {
      el._flatpickr.redraw()
    })

    this.updateHallsAvailability()
  }

  cinemaSelected() {
    console.log('cinema selected')
    this.updateHallsList()
  }

  clearHallsList() {
    for(var i = this.hallTarget.length - 1; i > 0; i--) {
      this.hallTarget.remove(i);
    }
    $(this.hallTarget).selectpicker('refresh');
    console.log('halls list cleared', this.hallTarget.length)
  }

  updateHallsList(cinema_id) {
    this.clearHallsList()
    if(this.cinemaTarget.value) {
      console.log('updating halls list…')
      var self = this
      $.rails.ajax({
        url: '/cinemas/' + this.cinemaTarget.value + '/halls',
        type: 'GET',
        dataType: 'json',
        success: function(response) {
          console.log(response)
          for(var i = 0; i < response.length; i++) {
            var data = response[i];
            var newOption = document.createElement("option");
            newOption.text = data.name_with_satellite
            newOption.value = data.id
            newOption.setAttribute('data-satellite', data.satellite_ready)
            $(self.hallTarget).append(newOption);
          }
          self.updateHallsAvailability()
        }
      });
    }
  }

  updateHallsAvailability() {
    var satelliteRequired = this.currentReleasesRequiresSattellite()
    for(var i = 1; i < this.hallTarget.length; i++) {
      var option = this.hallTarget.options[i]
      if(satelliteRequired && option.getAttribute('data-satellite') != 'true') {
        option.setAttribute('disabled', true)
      } else {
        option.removeAttribute('disabled')
      }
    }
    $(this.hallTarget).selectpicker('refresh');
  }

  addDateTime(e) {
    e.preventDefault()
    var template_id = e.target.getAttribute('data-template');
    // console.log('template_id', template_id)
    var template = $(template_id)[0].innerHTML
    // console.log('2', template)
    $(this.dateTimesTarget).append(template)
  }

  datetimeConnected(event) {
    console.log('datetimeConnected', event)
    var self = this
    // console.log('releaseDates', this.currentReleaseDates());
  	$(event.target).flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: 'd.m.Y H:i',
      wrap: true,
      locale: 'ru',
      disable: [
        function(date) {
          var releaseDates = self.currentReleaseDates()
          // console.log('releaseDates', releaseDates);
          if(releaseDates) {
            if(date < releaseDates.startDate) {
              return true
            }
            if(date > releaseDates.endDate) {
              return true
            }
            return false
          } else {
            return true
          }
        }
      ]
    })
  }
}
