import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "utmCampaignSource", "utmCampaignMedium" ]

  connect() {
    console.log("Shorten link form controller")
  }

  channelChanged(event) {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const utmCampaignSource = selectedOption.getAttribute("data-utm-campaign-source")
    const utmCampaignMedium = selectedOption.getAttribute("data-utm-campaign-medium")

    this.utmCampaignSourceTarget.value = utmCampaignSource
    this.utmCampaignMediumTarget.value = utmCampaignMedium
  }
}
