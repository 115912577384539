import { Controller } from "stimulus"

export default class extends Controller {
  static values = { titleId: Number, url: String }

  // connect() {
  //   console.log("project_title_controller", this.urlValue)
  // }

  updated() {
    console.log("updated")

    $.rails.ajax({
      url: this.urlValue,
      dataType: 'script',
    })
  }

  title_distribution_rights_changed(event) {
    if (event.detail.titleId != this.titleIdValue) {
      return
    }
    this.updated()
  }
}
