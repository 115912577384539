/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker')

console.log($)
import {} from 'jquery-ujs'

import 'jquery_ujs_remote_submit_unchecked_checkboxes'


import "controllers"

console.log('webpacker')

const langMap = {
    ru: "ru_RU",
    en: "en_US",
};
if (document.documentElement.lang in langMap) {
  require("bootstrap-select/js/i18n/defaults-" + langMap[document.documentElement.lang]);
} else {
  console.error("Unknown language " + document.documentElement.lang);
}

import 'bootstrap-icons/font/bootstrap-icons';
require("flatpickr/dist/flatpickr.css")

// jquery ui datepicker & bootstrap-datepicker conflict resolve
console.log("Here", $.fn.datepicker.noConflict)
if (!$.fn.bootstrapDP && $.fn.datepicker && $.fn.datepicker.noConflict) {
   var datepicker = $.fn.datepicker.noConflict();
   $.fn.bootstrapDP = datepicker;
}