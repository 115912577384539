import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var options = {
  		dateFormat: 'dd.mm.yy',
  		firstDay: 1,
      showButtonPanel: true
  	};

    if(this.data.has('minDate')) {
      // console.log(this.data.get('minDate'))
      options.minDate = this.data.get('minDate')
    }

    if(this.data.has('maxDate')) {
      // console.log(this.data.get('maxDate'))
      options.maxDate = this.data.get('maxDate')
    }

    if(this.data.has('min-listener')) {
      const $minListener = $(this.data.get('min-listener'))

      options.onSelect = function(date) {
        $minListener.datepicker("option", "minDate", date);
      };
    }

    if(this.data.has('max-listener')) {
      const $maxListener = $(this.data.get('max-listener'))

      options.onSelect = function(date) {
        $maxListener.datepicker("option", "maxDate", date);
      };
    }

  	$(this.element).datepicker(options);
  }
}
