import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "cinemaCheckBox" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    console.log('scheduling cinemas form controller')
    console.log(this.data.get("newUrl"))
    $(this.element).on('ajax:success', this.onSuccess.bind(this))
  }

  cinemaDateClicked(event) {
    event.preventDefault()

    const cinemaId = event.target.getAttribute('data-cinema-id')
    const starts_at = event.target.getAttribute('data-starts-at')

    this.element.querySelector('[name="cinema_ids[]"][value="'+cinemaId+'"]').checked = true
    this.element.querySelector('[name="starts_at"]').value = starts_at

    this.element.action = this.data.get("newUrl")
    // $(this.element).submit()
    // Rails.fire(this.element, 'submit');
    // $.rails.fire(this.element, 'submit')
    // $(this.element).trigger('submit.rails')
    $(this.element).trigger('submit')
  }

  editScreeningClicked(event) {
    event.preventDefault()

    const cinemaId = event.target.getAttribute('data-cinema-id')
    const starts_at = event.target.getAttribute('data-starts-at')

    this.element.querySelector('[name="cinema_ids[]"][value="'+cinemaId+'"]').checked = true
    this.element.querySelector('[name="starts_at"]').value = starts_at
    this.element.querySelector('[name="cinema_id"]').value = cinemaId

    this.element.action = this.data.get("editUrl")
    $(this.element).trigger('submit')
  }

  onSuccess() {
    this.element.querySelectorAll('[name="cinema_ids[]"]').forEach((el) => {
      el.checked = false
    })
  }
}
