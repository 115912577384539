import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "row" ]

  connect() {
    console.log('scheduling cinemas highlight selection controller')
  }
  
  search(event) {
    const term = event.target.value.toLowerCase()
    this.rowTargets.forEach((row) => {
      row.hidden = row.getAttribute('data-name').toLowerCase().indexOf(term) == -1
    })
  }
}
