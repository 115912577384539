// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "output" ]

  connect() {
    // const $currentDate = $('.scheduling-cinemas--table--header--current')
    // if($currentDate.length > 0) {
    //   const pos = $currentDate.position()
    //   $('.scheduling-cinemas--table').scrollLeft(pos.left - 220)
    // }
    $('body').css('paddingBottom', 0);
    // $(function() {
//       const height = $('.scheduling-cinemas--table').height()
//       $('.scheduling-cinemas--table-view').height(height);
//     });
  }

  maximize(e) {
    e.preventDefault()
    $('.scheduling-cinemas--table-view').addClass('scheduling-cinemas--table-view--maximized')
  }

  minimize(e) {
    e.preventDefault()
    $('.scheduling-cinemas--table-view').removeClass('scheduling-cinemas--table-view--maximized')
  }

  selectAll(e) {
    e.preventDefault()
    $('.scheduling-cinemas--table-view input[name="cinema_ids[]"]').prop('checked', true)
  }

  deselectAll(e) {
    e.preventDefault()
    $('.scheduling-cinemas--table-view input[name="cinema_ids[]"]').prop('checked', false)
  }
}
