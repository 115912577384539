import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "ccShareInput", "pricingPlanKindSelect" ]

  pricingPlanKindChanged() {
    const pricingPlanKindOption = this.pricingPlanKindSelectTarget.options[this.pricingPlanKindSelectTarget.selectedIndex]
    const ccShare = pricingPlanKindOption.getAttribute("data-cc-share")
    this.ccShareInputTarget.value = ccShare

    if (ccShare) {
      $(this.element).trigger("submit")
    }
  }
}
