import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "firstNameRu",
    "lastNameRu",
    "firstNameEn",
    "lastNameEn",

    "slug"
  ]

  change() {
    this.slugTarget.value = getSlug(this.name)
  }

  get name() {
    if (this.nameEn.length > 0) {
      return this.nameEn;
    }
    return this.nameRu;
  }

  get nameRu() {
    return this.concatenate(this.firstNameRuTarget.value, this.lastNameRuTarget.value)
  }

  get nameEn() {
    return this.concatenate(this.firstNameEnTarget.value, this.lastNameEnTarget.value)
  }

  concatenate(firstName, lastName) {
    const filteredNames = [firstName, lastName].filter(function(str) {
      return str.length > 0
    })
    return filteredNames.join(" ")
  }
}
