import { Controller } from "stimulus"

export default class extends Controller {
  static values = { searchUrl: String, agreementId: String }
  static targets = [
    "currentAgreementIdInput",
    "agreementSellerSelect",
    "agreementBuyerSelect",
    "agreementNameInput",
    "agreementDateInput",
    "existingAgreements"
  ]

  connect() {
    console.log("Distribution Right Form controller", this.agreementIdValue)
  }

  searchAgreements() {
    const params = {
      q: {
        seller_id_eq: this.agreementSellerSelectTarget.value,
        buyer_id_eq: this.agreementBuyerSelectTarget.value,
        document_title_cont: this.agreementNameInputTarget.value,
      }
    }

    if (this.agreementIdValue) {
      params.q.id_not_eq = this.agreementIdValue
    }

    $.rails.ajax({
      url: this.searchUrlValue,
      data: params,
      success: function(response) {
        this.existingAgreementsHTML = response

        if (this.hasCurrentAgreementIdInputTarget) {
          this.currentAgreementIdInputTarget.checked = true
        }
      }.bind(this),
    })
  }

  set existingAgreementsHTML(newValue) {
    this.existingAgreementsTarget.innerHTML = newValue
  }

  agreementIdChanged(event) {
    const newAgreementId = event.target.value
    if (newAgreementId && newAgreementId != this.agreementIdValue) {
      this.toggleAgreementFields(false)
    } else {
      this.toggleAgreementFields(true)
    }
  }

  toggleAgreementFields(enabled) {
    this.agreementSellerSelectTarget.disabled = !enabled
    this.agreementBuyerSelectTarget.disabled = !enabled
    this.agreementNameInputTarget.disabled = !enabled
    this.agreementDateInputTarget.disabled = !enabled
  }
}