import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "buyer", "agreement" ]

  connect() {
    console.log('accounting acts filter connected')
  }
  
  updateAgreementsList() {
    this.clearAgreementsList()
    if(this.buyerTarget.value) {
      this.loadAgreementsForBuyer(this.buyerTarget.value)
    }
  }
  
  loadAgreementsForBuyer(company_id) {
    const self = this
    $.rails.ajax({
      url: '/accounting/agreements?by_buyer_id=' + company_id,
      type: 'GET',
      dataType: 'json',
      success: function(response) {
        console.log(response)
        for(var i = 0; i < response.length; i++) {
          var data = response[i];
          var newOption = document.createElement("option");
          newOption.text = data.title
          newOption.value = data.id
          $(self.agreementTarget).append(newOption);
        }
      }
    });
  }
  
  clearAgreementsList() {
    for(var i = this.agreementTarget.length - 1; i > 0; i--) {
      this.agreementTarget.remove(i);
    }
  }
}
