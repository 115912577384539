import { Controller } from "stimulus"

export default class extends Controller {
  static values = { searchUrl: String }
  static targets = [ "sellerSelect", "buyerSelect", "nameInput", "currentAgreementInput" ]

  connect() {
    console.log("project_title_agreement_form_controller", this.searchUrlValue)
    let self = this
    $("#agreement_seller_id, #agreement_buyer_id").on("select2:select", function() {
      self.updated()
    })
  }

  updated() {
    console.log("updated")

    $.rails.ajax({
      url: this.searchUrlValue,
      dataType: 'script',
      data: {
        f: {
          buyer_id_eq: this.buyerSelectTarget.value,
          seller_id_eq: this.sellerSelectTarget.value,
          document_title_cont: this.nameInputTarget.value,
          id_not_eq: this.currentAgreementInputTarget.value,
        }
      }
    })
  }
}
