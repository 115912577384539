// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [ "item" ]

  connect() {
    const self = this
    self.sortable = Sortable.create(this.element, {
      onSort: function(e) {
        self.savePositions()
      }
    })
  }

  savePositions() {
    const url = this.data.get('update-positions-path')
    const sortedIds = this.sortable.toArray()

    $.rails.ajax({
      url: url,
      type: 'PATCH',
      data: {
        still_ids: sortedIds
      },
      // dataType: 'json',
      success: function(response) {
        console.log(response)
      }
    })
  }
}
