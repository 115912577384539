import Flatpickr from 'stimulus-flatpickr'
import { Russian } from "flatpickr/dist/l10n/ru.js"

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Russian
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates)
    console.log('but returns it also as a string', dateStr)
    console.log('and the flatpickr instance', instance)
  }
}



// import { Controller } from "stimulus"
//
// export default class extends Controller {
//   connect() {
//     console.log('flatpickr connected')
//     // var options = {
//   	// 	dateFormat: 'dd.mm.yy',
//   	// 	firstDay: 1,
//     //   showButtonPanel: true,
//     //   maxDate: this.element.dataset.maxDate,
//     //   minDate: this.element.dataset.minDate
//   	// };
//     // var minFor = this.element.dataset.minFor;
//     // if(minFor) {
//     //   options.onSelect = function(date) {
//     //     $(minFor).datepicker("option", "minDate", date);
//     //   };
//     // }
//     // var maxFor = this.element.dataset.maxFor;
//     // if(maxFor) {
//     //   options.onSelect = function(date) {
//     //     $(maxFor).datepicker("option", "maxDate", date);
//     //   };
//     // }
//     var options = {
//       enableTime: true,
//       time_24hr: true,
//       dateFormat: "d.m.Y H:i",
//       wrap: true,
//       locale: "ru"
//     }
//     // var $flatElement = $(this.element).wrap('<div class="flatpickr"></div>').parent('.flatpickr')
//     // $(this.element).attr('data-input', '')
//     //$flatElement.append('<a class="input-button" title="toggle" data-toggle><i class="icon-calendar"></i></a>')
//     //$flatElement.append('<a class="input-button" title="clear" data-clear><i class="icon-close"></i></a>')
//   	$(this.element).flatpickr(options)
//   }
// }
