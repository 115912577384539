import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "foregroundColorPicker" ]

  connect() {
    this.toolbar.querySelector(".trix-button-group--file-tools").remove()
    $(this.toolbar.querySelector(".trix-button-group--text-tools")).append(this.foregroundColorButtons)
  }

  openForegroundColorPicker () {
    this.foregroundColorPickerTarget.click()
  }

  foregroundColorChanged () {
    this.editorElement.editor.activateAttribute('foregroundColor', this.foregroundColorPickerTarget.value)
  }

  get editorElement() {
    const id = this.inputTarget.getAttribute("id")
    return document.querySelector(`[input=${id}]`)
  }

  get toolbar() {
    const id = this.editorElement.getAttribute("toolbar")
    return document.getElementById(id)
  }

  // https://github.com/basecamp/trix/issues/985#issuecomment-1307422240
  get foregroundColorButtons () {
    return `<input type="color" style="width:0;height:0;padding:0;margin-top:0px;visibility:hidden"
                   data-trix-stimulus-configuration-target="foregroundColorPicker" data-action="trix-stimulus-configuration#foregroundColorChanged">
            <button type="button" class="trix-button" data-action="click->trix-stimulus-configuration#openForegroundColorPicker" title="Text color">
              <span class="icon"><i class="fas fa-palette fa-lg"></i></span>
            </button>`
  }
}
